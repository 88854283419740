.imageContainer {
    border: 10px solid white;
}

@keyframes blink {
    0% {border-color: green;}
    50% {border-color: white;}
    100% {border-color: green;}
}

.blink {
    animation: blink 1s linear infinite;
}

.ant-table-fixed {
    table-layout: fixed;
}

.ant-table-tbody > tr > td {
    word-wrap: break-word;
    word-break: break-all;
}